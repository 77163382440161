import React from 'react';
import './TermsAndConditions.css';

export const BIRBLI_FEE_RATE = "23%";
export const BIRBLI_FEE_MIN_DOLLAR_AMOUNT = "$1.85";

export const TermsAndConditions = () => {
    return (
        <div className='container bg-transparent'>
            <h1 className='text-center fw-bold'>Terms and Conditions</h1>
            <p className='text-center'>Last Revision: February 16, 2025</p>

            <p className='mb-3'>
            By accessing or using Birbli (the "Website" and the "Application"), you agree to be bound by these Terms and Conditions and Birbli's Privacy Policy. The terms "You" or "User" refer to any individual or organization utilizing Birbli in any capacity.
            </p>

            <section className='mb-3'>
                <h2 className='fw-bold'>1. Service</h2>
                <p className='mb-3'>
                Birbli is an end-to-end encrypted messaging platform that allows users to sell their time through text-based communication. The messaging feature is free to use; however, users ("Sellers") who wish to sell their time can set their availability, hourly rate, and manage their interactions through the platform.
                </p>
                <ul className='terms-list'>
                    <li className='terms-list-item'><strong>Availability and Rates:</strong> Sellers are responsible for setting their availability and hourly rates. They maintain discretion over accepting or rejecting communication requests. Users are responsible for vetting any request made by other users.</li>
                    <li className='terms-list-item'><strong>Reservations:</strong> A reservation is a pre-scheduled period during which a buyer and a seller have agreed to communicate via Birbli. Reservations can be canceled by users at any time before payment is processed. Payments for reservations are prorated based on the seller’s hourly rate.</li>
                    <li className='terms-list-item'><strong>Platform Fee:</strong> {
                        `Birbli charges a processing fee for each reservation, which will be the greater of either ${BIRBLI_FEE_MIN_DOLLAR_AMOUNT} or ${BIRBLI_FEE_RATE} of the prorated reservation cost.`
                    }
                    </li>
                    <li className='terms-list-item'><strong>Payouts:</strong> Sellers receive payouts on a weekly basis via Stripe and are subject to Stripe’s terms and conditions. Sellers must connect a valid bank account through Stripe to receive payouts.</li>
                </ul>
            </section>

            <section className='mb-3'>
                <h2 className='fw-bold'>2. User Generated Content</h2>
                <p className='mb-3'>
                Birbli permits the sharing of user-generated content only through direct messaging, group chats, or in the context of an active reservation. Content cannot be shared outside of these contexts.
                </p>
                <ul className='terms-list'>
                    <li className='terms-list-item'><strong>Ownership and Access:</strong> All content shared through Birbli remains the property of the user. Birbli cannot access any messages or media shared through encrypted messages. However, public profile information may be accessible to other users and Birbli but will not be used by Birbli for any other purpose.</li>
                    <li className='terms-list-item'>Birbli reserves the right to limit what user-generated content is allowed on the app, and these limits may change without notice. Forbidden content consists of, but is not limited to:</li>
                    <ul className='terms-sublist'>
                        <li className='terms-list-item'>Hate Speech</li>
                        <li className='terms-list-item'>Gore</li>
                        <li className='terms-list-item'>Defamation</li>
                        <li className='terms-list-item'>Copyrighted Content</li>
                        <li className='terms-list-item'>Inappropriate Content</li>
                    </ul>
                    <li className='terms-list-item'>Birbli may, at its sole discretion, remove content it deems inappropriate or harmful. Any violation of these content policies may result in suspension of the user account. Birbli may involve legal authorities if required.</li>
                </ul>
            </section>

            <section className='mb-3'>
                <h2 className='fw-bold'>3. Phone Number Verification (Optional)</h2>
                <p className='mb-3'>
                Users may opt to link their phone number to the platform, either during sign-up or through their profile settings.
                </p>
                <p>
                SMS OTP: Birbli will send a one-time passcode (OTP) via SMS to verify the phone number. Users are responsible for ensuring that the phone number provided is correct and accessible. Standard message and data rates may apply.
                </p>
                <p>
                Contact Discovery: If a user opts to link a phone number to their Birbli account, this verified phone number will only be used for contact discovery within the platform. This means the phone number will help users connect with others who are also on the platform. The phone number will not be used for marketing or any other purpose outside of facilitating connections.
                </p>
            </section>

            <section className='mb-3'>
                <h2 className='fw-bold'>4. Refunds and Resale</h2>
                <p className='mb-3'>
                Refunds will be considered on a case-by-case basis.
                </p>
                <ul className='terms-list'>
                    <li className='terms-list-item'><strong>Reporting:</strong> Users can report a conversation through the application. Upon receiving a report, Birbli will investigate and decide whether a refund is warranted.</li>
                    <li className='terms-list-item'>The resale or transfer of Birbli accounts or services is strictly prohibited.</li>
                </ul>
            </section>

            <section className='mb-3'>
                <h2 className='fw-bold'>5. Disclaimer of Warranties</h2>
                <p className='mb-3'>
                Birbli is provided "as-is" without any warranties, express or implied. Birbli makes no guarantees regarding the availability, functionality, or reliability of the platform.
                </p>
            </section>

            <section className='mb-3'>
                <h2 className='fw-bold'>6. Limitation of Liability</h2>
                <p className='mb-3'>
                In no event shall Birbli or its affiliates be liable for any indirect, incidental, consequential, or punitive damages arising from the use or inability to use Birbli. This limitation applies to all claims, regardless of the legal theory or nature of the cause of action.
                </p>
            </section>
        </div>
    );
};
